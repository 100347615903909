<template>
  <div class="bottom-bar">
    <div class="logo-amis" @click="togglePopup">
      <img src="/amis-logo.png" alt="Amis" class="logo">
    </div>

    <transition name="fade">
      <div v-if="showPopup" class="friends-popup">
        <h3>Amis connectés</h3>
        <ul>
          <li v-for="friend in friends" :key="friend.id">
            <span class="friend-name">{{ friend.username }}</span>
            <span :class="['status-circle', friend.online ? 'online' : 'offline']"></span>
            <button class="remove-button" @click="removeFriend(friend.id)">❌</button>
          </li>
        </ul>

        <h3>Demandes d'amis</h3>
        <ul>
          <li v-for="request in friendRequests" :key="request.id">
            <span class="friend-name">{{ request.username }}</span>
            <button class="accept-button" @click="acceptFriend(request.id)">✔</button>
            <button class="remove-button" @click="rejectFriend(request.id)">❌</button>
          </li>
        </ul>

        <div class="input-container">
          <input v-model="newFriend" placeholder="Nom d'utilisateur" class="input">
          <button @click="addFriend" class="add-button">Ajouter un ami</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { io } from "socket.io-client";

export default {
  data() {
    return {
      showPopup: false,
      friends: [],
      friendRequests: [],
      newFriend: "",
      socket: null
    };
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
      if (this.showPopup) {
        this.fetchFriends();
        this.fetchFriendRequests();
      }
    },
    async fetchFriends() {
      try {
        const response = await fetch("/api/friends", { credentials: "include" });
        const data = await response.json();
        if (response.ok) {
          this.friends = data.friends;
        } else {
          console.error("Erreur récupération amis :", data.error);
        }
      } catch (error) {
        console.error("Erreur récupération amis :", error);
      }
    },
    async fetchFriendRequests() {
      try {
        const response = await fetch("/api/friends/requests", { credentials: "include" });
        const data = await response.json();
        if (response.ok) {
          this.friendRequests = data.requests;
        } else {
          console.error("Erreur récupération demandes d'amis :", data.error);
        }
      } catch (error) {
        console.error("Erreur récupération demandes d'amis :", error);
      }
    },
    async updateFriendStatuses() {
      try {
        const response = await fetch("/api/friends/status", { credentials: "include" });
        const data = await response.json();
        if (response.ok) {
          this.friends.forEach(friend => {
            const updatedFriend = data.status.find(f => f.id === Number(friend.id));
            if (updatedFriend) {
              friend.online = updatedFriend.online;
            }
          });
        }
      } catch (error) {
        console.error("Erreur mise à jour des statuts :", error);
      }
    },
    async addFriend() {
      if (!this.newFriend) return;
      try {
        const response = await fetch("/api/friends/request", {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ friend_username: this.newFriend })
        });
        const data = await response.json();
        if (response.ok) {
          this.fetchFriendRequests();
        } else {
          console.error("Erreur ajout ami :", data.error);
        }
      } catch (error) {
        console.error("Erreur ajout ami :", error);
      }
    },
    async acceptFriend(friendId) {
      try {
        await fetch(`/api/friends/accept/${friendId}`, { method: "POST", credentials: "include" });
        this.fetchFriends();
        this.fetchFriendRequests();
      } catch (error) {
        console.error("Erreur acceptation ami :", error);
      }
    },
    async rejectFriend(friendId) {
      try {
        await fetch(`/api/friends/remove/${friendId}`, { method: "DELETE", credentials: "include" });
        this.fetchFriendRequests();
      } catch (error) {
        console.error("Erreur suppression ami :", error);
      }
    },
    removeFriend(friendId) {
      this.rejectFriend(friendId);
      this.fetchFriends();
    },
    setupSocket() {
      this.socket = io("https://avgmq.net", { withCredentials: true });

      this.socket.on("friend_status_update", ({ friendId, online }) => {
        const friend = this.friends.find(f => f.id === friendId);
        if (friend) {
          this.$set(friend, "online", online);
        }
      });
    }
  },
  mounted() {
    this.fetchFriends();
    this.setupSocket();

    // Mettre à jour toutes les 5 secondes
    this.updateInterval = setInterval(() => {
      this.fetchFriends();
      this.updateFriendStatuses();
    }, 5000);
  },
  beforeUnmount() {
    // Arrêter l'intervalle lors de la destruction du composant
    clearInterval(this.updateInterval);
  }
};
</script>

<style scoped>
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: rgba(51, 51, 51, 0.7);
  color: white;
  padding: 5px 10px;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.logo-amis {
  cursor: pointer;
  padding: 10px;
}

.logo {
  width: 30px;
  height: 30px;
}

.friends-popup {
  position: absolute;
  bottom: 50px;
  left: 10px;
  background-color: #111;
  color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  width: 280px;
}


.friends-popup h3 {
  margin-bottom: 10px;
}

.friends-popup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.friends-popup li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.friend-name {
  flex-grow: 1;
  text-align: center;
}

.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.online {
  background-color: green;
}

.offline {
  background-color: red;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Ajoute un espacement entre l'input et le bouton */
  margin-top: 10px;
}

.input {
  width: calc(100% - 16px);
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #222;
  color: white;
}

.add-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  width: calc(100% - 16px);
  margin: 0 auto;
  display: block;
}

.accept-button,
.remove-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 4px 8px;
}

.accept-button:hover {
  background-color: rgba(40, 167, 69, 0.3);
}

.remove-button:hover {
  background-color: rgba(220, 53, 69, 0.3);
}
</style>
